import React, { useState, useEffect } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer } from "recharts";
import axios from "axios";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import SyncLoader from "react-spinners/SyncLoader";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ExcelButton from "../General/ExcelButton";

dayjs.extend(quarterOfYear);

function TopTwentyMediaChart({ endpoint, title, isPresenterFieldNeeded, isSearchable, isExcelButtonOnlyNeeded }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [queryParameters, setQueryParameters] = useState([]);
  const [isError, setIsError] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleChangeInputType = (event, inputType) => {
    if (!event.target.value) event.target.type = inputType;
  };

  const handleDate = event => {
    const date = dayjs(event.target.value).format("YYYY-MM-DD");
    setQueryParameters({ ...queryParameters, [event.target.name]: date });
  };

  const handlePeriodData = async () => {
    if (queryParameters?.from > queryParameters?.to) {
      setIsError(true);
      return;
    }
    try {
      setIsError(false);
      setIsLoading(true);
      let response = await axios.get(`${process.env.REACT_APP_DEV_BASEURL}/${endpoint}`, {
        params: { ...queryParameters },
      });

      setData(response.data.results);
      setIsLoading(false);
      setQueryParameters([]);
    } catch (error) {
      if (error.response) {
        // Request made but the server responded with an error
        console.log(error.response.data);
      } else if (error.request) {
        // Request made but no response is received from the server.
        console.log(error.request);
      } else {
        // Error occured while setting up the request
        console.log("Error", error.message);
      }
    }
  };

  const renderActiveShape = props => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      percent,
      value,
      title,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {value}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
          {`${title.length > 40 ? title.slice(0, 40) + "..." : title}`}
        </text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  const handlePieEnter = (_, index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        let response = await axios.get(`${process.env.REACT_APP_DEV_BASEURL}/${endpoint}`, {
          params: {
            from: dayjs().subtract(6, "months").format("YYYY-MM-DD"),
            to: dayjs().format("YYYY-MM-DD"),
          },
        });

        setData(response.data.results);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          // Request made but the server responded with an error
          console.log(error.response.data);
        } else if (error.request) {
          // Request made but no response is received from the server.
          console.log(error.request);
        } else {
          // Error occured while setting up the request
          console.log("Error", error.message);
        }
      }
    }

    fetchData();
  }, []);

  return isLoading ? (
    <>
      <div className="wrapper">
        <SyncLoader color="#8884d8" />
      </div>
    </>
  ) : (
    <>
      <div className="wrapper">
        <div className="chartContainer mx-auto bg-white">
          <Row className="m-0">
            <Col className="py-2">
              <span className="chartTitle mt-4">{title}</span>
            </Col>
          </Row>
          {isSearchable && (
            <Row className="justify-content-center mx-0 bg-white">
              <InputGroup className="align-items-baseline w-75">
                <Form.Control
                  aria-label="From"
                  type="text"
                  id="from"
                  name="from"
                  className="mx-1"
                  onChange={handleDate}
                  onFocus={event => handleChangeInputType(event, "date")}
                  onBlur={event => handleChangeInputType(event, "text")}
                  placeholder="From"
                  max={dayjs().format("YYYY-MM-DD")}
                />
                <Form.Control
                  aria-label="To"
                  type="text"
                  id="to"
                  name="to"
                  className="mx-1"
                  onChange={handleDate}
                  onFocus={event => handleChangeInputType(event, "date")}
                  onBlur={event => handleChangeInputType(event, "text")}
                  placeholder="To"
                  max={dayjs().format("YYYY-MM-DD")}
                />
                <Button
                  variant="secondary"
                  id="button-addon1"
                  onClick={handlePeriodData}
                  className="mx-2"
                  disabled={!(queryParameters?.from && queryParameters?.to)}>
                  Search
                </Button>
                <ExcelButton
                  data={data}
                  name={title}
                  isGreenVariant={true}
                  firstColumn="title"
                  secondColumn="Views"
                  isOtherfieldsPresent={false}
                  isPresenter={isPresenterFieldNeeded}
                />
              </InputGroup>
            </Row>
          )}

          {isExcelButtonOnlyNeeded && (
            <ExcelButton
              data={data}
              name={title}
              isGreenVariant={true}
              firstColumn="title"
              secondColumn="Views"
              isOtherfieldsPresent={false}
              isPresenter={isPresenterFieldNeeded}
            />
          )}
          {data.length > 0 ? (
            <ResponsiveContainer width="90%" height="90%">
              <PieChart>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={data.slice(0, 100)}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="Views"
                  onMouseEnter={handlePieEnter}
                />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <Row className="m-0 h-75 align-content-center my-5">
              <p className="fs-5 fw-bold mt-4">sorry, no data available to show right now</p>
            </Row>
          )}
        </div>
      </div>
    </>
  );
}

export default TopTwentyMediaChart;
